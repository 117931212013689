import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/compat/app";
// import initializeApp from "firebase/compat/app";
import config from "./firebase-config.json"; // Config Json File
// import Firebase from './components/Firebase/firebase';
// import { FirebaseContext } from './components/Firebase/context';

firebase.initializeApp(config);

ReactDOM.render(
    // <FirebaseContext.Provider value={new Firebase()}>
    //     <App />
    // </FirebaseContext.Provider>, 
    <App />
    , document.getElementById("root"),
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
