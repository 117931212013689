import React, { Component } from "react";
import { IconContext } from "react-icons";
import { VscFoldUp, VscFoldDown } from "react-icons/vsc";
import "./History.css";

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyExtend: false,
        }
        this.toggleHistory = this.toggleHistory.bind(this);
    }

    toggleHistory() {
        this.setState((prevState) => ({ historyExtend: !prevState.historyExtend }));
    }

    renderHistoryIcon() {
        if (!this.props.history) return;

        const icon = this.state.historyExtend ? <VscFoldDown /> : <VscFoldUp />;
        return (
            <div className="historyIcon" onClick={this.toggleHistory}>
                <IconContext.Provider value={{ size: "1em", style: { paddingRight: "0.2rem", marginTop: "0.1rem" } }}>
                    {icon}
                </IconContext.Provider>
            </div>
        );
    }

    render() {
        const { history } = this.props;
        const { historyExtend } = this.state;

        return (
            <div>
                <div>
                    {this.renderHistoryIcon()}
                </div>
                <div className={history && historyExtend ? "historyMenu" : "historyMenuClose"}>
                    {historyExtend && Object.keys(history).map((i) => (
                        <div key={i} className="historyCarousel">
                            <span className="historyCarousel2">interviewsandbox.com/box/{i}</span>
                            <p>{history[i]}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default History;