import React, { useState, useEffect } from "react";
import "./Toggle.scss";

const Toggle = ({ darkMode, setMode, simulateMouseClick }) => {
  const [localDarkMode, setLocalDarkMode] = useState(darkMode);

  useEffect(() => {
    setLocalDarkMode(darkMode);
  }, [darkMode]);

  const handleClick = () => {
    if (localDarkMode) {
      document.body.style.backgroundColor = "#10131c";
    } else {
      document.body.style.backgroundColor = "#000";
    }
    setMode();
  };

  return (
    <div className="toggleWrapper">
      <input type="checkbox" className="dn" id="dn" />
      <label
        htmlFor="dn"
        className="toggle"
        onClick={handleClick}
      >
        <span className="toggle__handler">
          <span className="crater crater--1"></span>
          <span className="crater crater--2"></span>
          <span className="crater crater--3"></span>
        </span>
        <span className="star star--1"></span>
        <span className="star star--2"></span>
        <span className="star star--3"></span>
        <span className="star star--4"></span>
        <span className="star star--5"></span>
        <span className="star star--6"></span>
      </label>
    </div>
  );
};

export default Toggle;
