import React from "react";
import "./Card.css";
import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";
import { VscCopy } from "react-icons/vsc";
import OpenAI from "./openai.png";
import HuggingFace from "./huggingfaceicon.png";
import anthropic from "./anthropic.png";
import PaLM from "./Google_PaLM_Logo.png";
import Gemini from "./Google-Gemini.webp";

class Card extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            usage: this.props.usage, 
            output: "",
            model: this.props.model,
            cardCreated: this.props.cardCreated,
            darkMode: this.props.darkMode,
            cardOutput: "",
            output2: ""
        }
        this.ai = this.ai.bind(this);
    }
    async componentWillReceiveProps(nextProps){
        // console.log("nextProps: ", nextProps);
        this.setState({
            darkMode: nextProps.darkMode,
        })
    }
    
    ai() {
        const modelData = {
            "hf": {
                title: "HuggingFace",
                src: HuggingFace,
                alt: "huggingface",
                extraText: "@meta-llama/Llama-2-7b-chat-hf"
            },
            "openai": {
                title: "OpenAI",
                src: OpenAI,
                alt: "openai",
                extraText: ""
            },
            "anthropic": {
                title: "Anthropic",
                src: anthropic,
                alt: "anthropic",
                extraText: ""
            },
            "Google PaLM": {
                title: "Google PaLM",
                src: PaLM,
                alt: "PaLM",
                extraText: ""
            },
            "Google Gemini": {
                title: "Google Gemini",
                src: Gemini,
                alt: "Gemini",
                extraText: ""
            }
        };
    
        const currentModel = modelData[this.props.model];
    
        if (!currentModel) {
            return null; // or return a default card if necessary
        }
    
        return (
            <div className="cardInfo">
                <div className="cardTitle">
                    <img title={currentModel.title} className="openai" src={currentModel.src} alt={currentModel.alt} />
                    <div className="codex poweredBy neonText">
                        {currentModel.title} {currentModel.extraText}
                    </div>
                </div>
                <div className="line">{this.props.output.join("")}</div>
                <p className={this.state.darkMode === false ? "cardCreated" : "cardCreated-dark"}>
                    {this.props.cardCreated}
                </p>
            </div>
        );
    }
    
    render() {
    const cardClass = this.state.darkMode ? "card-dark" : "card";

    const renderIconButton = (icon, action) => (
        <div className={`${action}Btn`} onClick={() => this.props[action + 'Card'](this.props.index)}>
            <IconContext.Provider value={{ size: "1.15em", style: { paddingRight: "0.2rem", marginTop: "0.1rem" } }}>
                {icon}
            </IconContext.Provider>
        </div>
    );

    return (
        <div className={cardClass}>
            <div className="cardBtns">
                {renderIconButton(<AiOutlineClose />, 'close')}
                {renderIconButton(<VscCopy />, 'copy')}
            </div>
            {this.ai()}
        </div>
    );
}

}
export default Card;