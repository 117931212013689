import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import CodingPage from "../pages/Coding";
import HomePage from "../pages/Home";
import Toggle from "../../components/Toggle";
import { RemoveScroll } from "react-remove-scroll";
import Hotkeys from 'react-hot-keys';
import SignupPage from "../pages/Signup";
import LoginPage from "../pages/Login";
import InterviewsPage from "../pages/Interviews";
import Interview from "../pages/Interview";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import AppRouter from './App.router'

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: null, darkMode: false };
    this.setMode = this.setMode.bind(this);
    this.simulateMouseClick = this.simulateMouseClick.bind(this);
  }

  onKeyUp(keyName, e, handle) {
    // console.log("test:onKeyUp", e, handle)
    this.setState({
      output: `onKeyUp ${keyName}`,
    });
    // this.setMode();
  }
  onKeyDown(keyName, e, handle) {
    // console.log("test:onKeyDown", keyName, e, handle)
    this.setState({
      output: `onKeyDown ${keyName}`,
    });
    var element = document.querySelector('.toggle__handler');
    this.simulateMouseClick(element);
  }

  simulateMouseClick(element){
    let mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })
      )
    );
  }

  setMode = () => {
    this.setState({ darkMode: !this.state.darkMode });
    sessionStorage.setItem("darkMode", !this.state.darkMode);
  };

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // eslint-disable-next-line 
        const uid = user.uid;
        // console.log("USER: ", user);
        // localStorage.setItem("user", JSON.stringify(user));
        this.setState({
          user: user,
          login: true,
          uid: uid
        })
        // ...
      } else {
        // User is signed out
        // ...
        this.setState({
          login: false,
        })
      }
    });

    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "8cf64773-bd33-4bfa-9b90-008d5d033135";

    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
    if (sessionStorage.getItem("darkMode") === "true") {
      var element = document.querySelector('.toggle__handler');
      this.simulateMouseClick(element);
    }

    const mq = window.matchMedia('(prefers-color-scheme: dark)');

    mq.addEventListener('change', (evt) => {
      // console.log(`${evt.matches ? 'dark' : 'light'} mode`, "evt.matches: ", evt.matches);
      var element = document.querySelector('.toggle__handler');
      this.simulateMouseClick(element);
      this.setState({
        darkMode: evt.matches
      })

    });
  }

  render() {
    return (
      <Router>
        <RemoveScroll>
          <Hotkeys
          keyName="shift+t,alt+s" 
          onKeyDown={this.onKeyDown.bind(this)}
          onKeyUp={this.onKeyUp.bind(this)}
          >
            <div className={this.state.darkMode === false ? "App" : "App-dark"}>
              <Toggle setMode={this.setMode} darkMode={this.state.darkMode} simulateMouseClick={this.simulateMouseClick.bind(this)} />
              <Route exact path="/" render={(props) => <HomePage {...props} darkMode={this.state.darkMode} />} />
              {/* <Route path="/:sessionid" render={(props) => <CodingPage {...props} darkMode={this.state.darkMode} />} /> */}
              <Route path="/box/:sessionid" render={(props) => <CodingPage {...props} darkMode={this.state.darkMode} />} />
              <Route path="/letsgo" render={(props) => <SignupPage {...props} darkMode={this.state.darkMode} />}/>
              <Route path="/login" render={(props) => <LoginPage {...props} darkMode={this.state.darkMode} />}/>
              <Route exact path="/interviews" render={(props) => <InterviewsPage {...props} darkMode={this.state.darkMode} />}/>
              <Route exact path="/interviews/:sessionid" render={(props) =>  <Interview {...props} darkMode={this.state.darkMode} uid={this.state.uid}/>}/>
            </div>
          </Hotkeys>
        </RemoveScroll>
      </Router>
    );
  }
}

