import React from "react";
import "./Timer.css";
import { IconContext } from "react-icons/lib";
import { FaChevronRight } from 'react-icons/fa';

class Timer extends React.Component {
  state = {
    minutes: 30,
    seconds: 0
  };

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  startTimer = () => {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState({ seconds: seconds - 1 });
      } else if (minutes > 0) {
        this.setState({
          minutes: minutes - 1,
          seconds: 59
        });
      } else {
        clearInterval(this.myInterval);
      }
    }, 1000);
  };

  setTimer = (event) => {
    this.setState({
      minutes: event.target.value
    });
  };

  render() {
    const { minutes, seconds } = this.state;
    const themeClass = this.props.theme === "Terminal" ? "time" : "time-plain";

    return (
      <div>
        <div className={themeClass}>
          {minutes === 0 && seconds === 0 ? (
            <h1>Busted!</h1>
          ) : (
            <h1>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </h1>
          )}
        </div>
        <div className="outer-div">
          <div className="t user-box">
            <input
              type="text"
              onChange={this.setTimer}
              className={themeClass}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <label className={themeClass}>Set</label>
          </div>
          <div className="goBtn" onClick={this.startTimer}>
            <IconContext.Provider value={{ size: "0.8em", style: { paddingLeft: "0.35rem" } }}>
              <FaChevronRight />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default Timer;
