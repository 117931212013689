import React, { useState } from "react";
import { IconContext } from "react-icons";
import {
  FaAngleDown,
  FaAngleUp,
  FaJsSquare,
  FaPython,
  FaGem,
  FaSwift,
  FaJava,
  FaGoogle,
  FaAndroid,
  FaPhp,
  FaRProject,
  FaBars,
} from "react-icons/fa";
import { SiCplusplus } from "react-icons/si";
import { BsCursorText } from "react-icons/bs";
import "font-awesome/css/font-awesome.min.css";
import "./Dropdown.css";

const ICONS = {
  FaJsSquare: FaJsSquare,
  FaPython: FaPython,
  FaGem: FaGem,
  FaSwift: FaSwift,
  FaJava: FaJava,
  FaGoogle: FaGoogle,
  FaAndroid: FaAndroid,
  FaPhp: FaPhp,
  FaRProject: FaRProject,
  FaBars: FaBars,
  SiCplusplus: SiCplusplus,
  BsCursorText: BsCursorText,
};

const LANG_ICON_MAP = {
  Python: FaPython,
  Javascript: FaJsSquare,
  Ruby: FaGem,
  Swift: FaSwift,
  Java: FaJava,
  Go: FaGoogle,
  Kotlin: FaAndroid,
  PHP: FaPhp,
  R: FaRProject,
  Scala: FaBars,
  "C++": SiCplusplus,
  plaintext: BsCursorText,
};

const Dropdown = ({ title, list, toggleItem, currLang }) => {
  const [listOpen, setListOpen] = useState(false);

  const toggleList = () => setListOpen((prevState) => !prevState);

  return (
    <div className="dd-wrapper" onMouseEnter={toggleList} onMouseLeave={toggleList}>
      <div className="dd-header">
        <div className="dd-header-title">
          {title}
          {listOpen ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {listOpen && (
        <ul className="dd-list">
          {list.map((item) => (
            <li
              className="dd-list-item"
              key={item.id}
              onClick={() => toggleItem(item.id, item.key)}
            >
              {ICONS[item.fa_icon] && React.createElement(ICONS[item.fa_icon])}
              {item.title}
            </li>
          ))}
        </ul>
      )}
      <span className="btn__label">
        <IconContext.Provider value={{ size: "0.5rem" }}>
          {LANG_ICON_MAP[currLang] && React.createElement(LANG_ICON_MAP[currLang])}
          {" "}
          {currLang}
        </IconContext.Provider>
      </span>
    </div>
  );
};

export default Dropdown;
