import React from "react";
import EliteSpinner from "./EliteSpinner.js";
import DropdownTheme from "./DropdownTheme";
// import database from "firebase/compat/";
import { IconContext } from "react-icons";
// import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { CiTimer } from "react-icons/ci";
import { TbBorderLeft,TbPencil, TbPencilOff } from "react-icons/tb";
import {  } from "react-icons/tb";
// import { CgStopwatch } from "react-icons/cg";
import Timer from "./Timer";
import { RemoveScroll } from "react-remove-scroll";
import "./Eval.css";
import "../fonts/Modenine-2OPd.ttf";
import OpenAI from "./openai.png";
import HuggingFace from "./huggingfaceicon.png";
import anthropic from "./anthropic.png";
import PaLM from "./Google_PaLM_Logo.png";
import Gemini from "./Google-Gemini.webp"
import Copilot from "./github-copilot.png";

class Eval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: this.props.code,
      language_id: this.props.language_id,
      token: this.props.token,
      evalOutput: this.props.evalOutput !== null ? this.props.evalOutput : sessionStorage.getItem("evalOutput"),
      evalOutputDB: this.props.evalOutputDB,
      memory: this.props.memory,
      time: this.props.time,
      loading: this.props.loading,
      sessionid: this.props.sessionid,
      mode: "plain",
      compileOutput: this.props.compileOut,
      evalExtend: this.props.evalExtend,
      errOutput: this.props.errOutput,
      timerExtend: this.props.timerExtend,
      flanT5Toggle: this.props.flanT5Toggle,
      anthropicToggle: this.props.anthropicToggle,
      PaLMToggle: this.props.PaLMToggle,
      GeminiToggle: this.props.GeminiToggle,
      theme: [
        {
          id: 0,
          mode: "plain",
          key: "theme",
          title: "Plain",
          selected: false,
        },
        {
          id: 1,
          mode: "terminal",
          key: "theme",
          title: "Terminal",
          selected: false,
        },
        // {
        //   id: 2,
        //   mode: "nintendoid",
        //   key: "theme",
        //   title: "Nintendoid",
        //   selected: false,
        // },
      ],
      currTheme: "Plain",
    };
    this.toggleTheme = this.toggleTheme.bind(this);
    this.runCodeEval = this.runCodeEval.bind(this);
  }
  componentDidMount() {
    const keysToGet = ["keyTheme", "mode", "currTheme", "compileOutput", "evalOutput"];
    
    const stateUpdate = keysToGet.reduce((acc, key) => {
        acc[key] = sessionStorage.getItem(key);
        return acc;
    }, {});

    this.setState(stateUpdate);
}

  onKeyUp(keyName, e, handle) {
    console.log("test:onKeyUp", e, handle)
    this.setState({
      output: `onKeyUp ${keyName}`,
    });
  }
  onKeyDown(keyName, e, handle) {
    console.log("test:onKeyDown", keyName, e, handle)
    this.setState({
      output: `onKeyDown ${keyName}`,
    });
    this.runCode()
  }

  //Lets you select between JS, Python, Ruby
  toggleTheme(id, key, mode) {
    let temp = this.state[key];
    temp[id].selected = !temp[id].selected;
    let newMode = this.state[key][id];
    this.setState({
      [key]: temp,
      mode: newMode["mode"],
      currTheme: newMode["title"],
    });
    sessionStorage.setItem("keyTheme", temp);
    sessionStorage.setItem("modeTheme", newMode["mode"]);
    sessionStorage.setItem("currTheme", newMode["title"]);
  }

  runCodeEval = () => {
    this.props.runCode();
  }

  //As the user enters code into the editor, our Eval component receives relevant info
  //that is used for downstrream functions.
  async componentWillReceiveProps(newProps) {
    this.setState({
      code: newProps.code,
      language_id: newProps.language_id,
      sessionid: this.props.sessionid,
      loading: this.props.loading,
      token: this.props.token,
      evalOutput: this.props.evalOutput,
      compileOutput: this.props.compileOutput,
      errOut: this.props.errOut,
      time: this.props.time,
      memory: this.props.memory,
      evalExtend: this.props.evalExtend,
      evalOutputDB: this.props.evalOutputDB,
      // openAiToggle: this.props.openAiToggle
    });
  }

  render() {
    let evalOutput = this.props.evalOutput;
    let sessionOutput = sessionStorage.getItem("evalOutput");
    let loading = this.props.loading;
    let time = this.props.time;
    let memory = this.props.memory;
    let evalOutputDB = this.props.evalOutputDB;
    //We conditionally render "Terminal output here" or the result of
    //our code execution, if it exists in our database.
    const renderOutput = (output) => {
      return output.split('\n').map((i, index) => <p key={index}>{i}</p>);
    };
    
    const renderWithScroll = (output) => {
      return (
        <RemoveScroll className={
          this.state.currTheme === "Terminal"
            ? "codeRes scroll"
            : "codeRes-plain scroll"
        }>
          <p>{output}</p>
        </RemoveScroll>
      );
    };
    
    const conditionalRender = () => {
      if (loading) return <EliteSpinner />;
      
      if (!loading && !evalOutput && !sessionOutput && !evalOutputDB) {
        return <p className="codeRes-plain">Terminal output here.</p>;
      }
      
      if (evalOutput) {
        return renderWithScroll(renderOutput(evalOutput));
      }
      
      if (evalOutputDB && !evalOutput && !sessionOutput) {
        return renderWithScroll(renderOutput(evalOutputDB));
      }
      
      if (evalOutputDB) {
        return renderWithScroll(renderOutput(evalOutputDB));
      }
    
      if (sessionOutput) {
        return <p className="codeRes-plain">{sessionOutput}</p>;
      }
    };

    const timeRender = () => {
      if (time) {
        return <div className="memory">Time: {time}s Memory: {memory} kb</div>
      }
    }

    const timerIcon = () => {
      const { timerExtend, toggleTimer } = this.props;
      const { currTheme } = this.state;
    
      return (
        <div>
          <div className="timerDiv">
            {openAiIcon()}
            {PaLMIcon()}
            {GeminiIcon()}
            {huggingFaceIcon()}
            {anthropicIcon()}
            {completionsIcon()}
            <div title="timer" className="extendBtn" onClick={toggleTimer}>
              <IconContext.Provider value={{ size: "1.5em", style: { paddingRight: "0.2rem", marginTop: "0.1rem" } }}>
                <CiTimer />
              </IconContext.Provider>
            </div>
            {whiteboardIcon()}
            {borderIcon()}
          </div>
    
          {!timerExtend && <Timer theme={currTheme} />}
        </div>
      );
    }
    

    const whiteboardIcon = () => {
      const { whiteboardExtend, toggleWhiteboard } = this.props;
      
      const Icon = whiteboardExtend ? TbPencilOff : TbPencil;
    
      return (
        <div title="whiteboard" className="extendBtn" onClick={toggleWhiteboard}>
          <IconContext.Provider value={{ size: "1.35em", style: { paddingRight: "0.2rem", marginTop: "0.1rem" } }}>
            <Icon />
          </IconContext.Provider>
        </div>
      );
    }
    

    const openAiIcon = () => {
      const { login, openAiToggle, toggleOpenAI } = this.props;
    
      if (!login) return null;
    
      return (
        <div className="extendBtn" onClick={toggleOpenAI}>
          <img 
            title="OpenAI" 
            className={openAiToggle ? "openai" : "openai-off"} 
            src={OpenAI} 
            alt="openai" 
          />
        </div>
      );
    }
    

    const borderIcon = () => {
      const { whiteboardExtend, toggleBorder } = this.props;
    
      if (!whiteboardExtend) return null;
    
      return (
        <div className="extendBtn" onClick={toggleBorder}>
          <IconContext.Provider value={{ size: "1.35em", style: { paddingRight: "0.2rem", marginTop: "0.1rem" } }}>
            <TbBorderLeft />
          </IconContext.Provider>
        </div>
      );
    }
    

    const completionsIcon = () => {
      const { login, completionToggle, toggleCompletion } = this.props;
    
      if (!login) return null;
    
      return (
        <div className="extendBtn" onClick={toggleCompletion}>
          <img 
            title="Copilot" 
            className={completionToggle ? "openai" : "openai-off"} 
            src={Copilot} 
            alt="Copilot"
          />
        </div>
      );
    }
    
    const huggingFaceIcon = () => {
      const { flanT5Toggle, toggleFlanT5 } = this.props;
    
      return (
        <div className="extendBtn" onClick={toggleFlanT5}>
          <img 
            title="Huggingface" 
            className={flanT5Toggle ? "openai" : "openai-off"} 
            src={HuggingFace} 
            alt="huggingface"
          />
        </div>
      );
    }
    
    const anthropicIcon = () => {
      const { anthropicToggle, toggleAnthropic } = this.props;
    
      return (
        <div className="extendBtn" onClick={toggleAnthropic}>
          <img 
            title="Anthropic" 
            className={anthropicToggle ? "openai" : "openai-off"} 
            src={anthropic} 
            alt="anthropic"
          />
        </div>
      );
    }

    const GeminiIcon = () => {
      const { GeminiToggle, toggleGemini } = this.props;
    
      return (
        <div className="extendBtn" onClick={toggleGemini}>
          <img 
            title="Google Gemini" 
            className={GeminiToggle ? "openai" : "openai-off"} 
            src={Gemini}  
            alt="Gemini"
          />
        </div>
      );
    }
    
    const PaLMIcon = () => {
      const { PaLMToggle, togglePaLM } = this.props;
    
      return (
        <div className="extendBtn" onClick={togglePaLM}>
          <img 
            title="Google PaLM" 
            className={PaLMToggle ? "openai" : "openai-off"} 
            src={PaLM}  
            alt="PaLM"
          />
        </div>
      );
    }

    const { darkMode } = this.props;
    const { theme } = this.state;
    return (
      <div className="evalOut">
        {conditionalRender()}
        <div>
          {timeRender()}
          {timerIcon()}
          <button 
            id="runButton" 
            className={`btn ${darkMode ? "letsRun-dark" : "letsRun"}`} 
            onClick={this.runCodeEval}
          >
            Run
          </button>
          <DropdownTheme
            toggleItem={this.toggleTheme}
            title="Theme "
            theme={theme}
          />
        </div>
      </div>
    );
  }
}

export default Eval;
