import React from "react";
import "./EliteSpinner.scss";

class EliteSpinner extends React.Component {
    render() {
        return (
            <div className='loader loader3'>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EliteSpinner;