import React from "react";
import Header from "../../components/Header";
import "./Interviews.css";
import rand from "random-key";
import { getAuth, setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, child, set, get } from "firebase/database";
import {GrAdd} from "react-icons/gr";
import { IconContext } from "react-icons";
import { Link } from 'react-router-dom';
// import { get } from "lodash";

export default class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: "",
        }
        this.addInteview = this.addInteview.bind(this);
        // this.renderInterviews = this.renderInterviews.bind(this);
    }

    componentDidMount = () => {
        const auth = getAuth();
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.setState({
                        uid: user.uid
                    })
                    // console.log("user is logged in", user);
                } else {
                    // console.log("user is not logged in");
                    this.props.history.push("/login");
                }
            });
        }).catch((error) => {
            console.log(error);
        });
   
        const dbRef = ref(getDatabase());
        if(this.state.uid === ""){
            get(child(dbRef, `interviews/${this.state.uid}`)).then((snapshot) => {
                if(snapshot.exists()) {
                    // console.log(Object.keys(snapshot.val()[this.state.uid]))
                    this.setState({
                        interviews: Object.keys(snapshot.val()[this.state.uid]),
                        interviewsData: snapshot.val()
                    })
                } else {
                    console.log("No data available.")
                }
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    addInteview( ) {
        console.log("add interview");
        const dbRef = ref(getDatabase());
        let key = rand.generate(6)
        get(child(dbRef, `interviews/${this.state.uid}`), (s) => {
            if (s.exists()) {
                console.log("snapshot", s);
            }
        })
        set(child(dbRef, `interviews/${this.state.uid}/${key}`), {
            content:
          '# Welcome to your Interview Sandbox! \n\n# 1 - Just share this link with another person \n# \t  to get them on the same page.\n# 2 - Once they join, you\'ll see the call button.\n# 3 - The code, code output, and drawings are synced\n#     in real time between users.\n# 4 - Select between Javascript, Python, Ruby, Swift,\n#     Java, Go, Kotlin, PHP, R, and Scala.\n\n# Try running the code below to see the ouput!\n\nprint("hello world")',
            createdon: new Date().toLocaleString(),
        })
    }

    render() {

        const renderInterviews = () => {
            if (this.state.interviews != null) {
                // Object.keys(snapshot.val()).map((key) => {
                //     console.log("key", key, snapshot.val()[key].createdon);
                //     return (
                //         <div className="interview">
                //             <div className="interviewName">
                //                 {/* {snapshot.val()[key].name} */}
                //                 Hello World
                //             </div>
                //             <div className="interviewDate">
                //                 {snapshot.val()[key].createdon}
                //             </div>
                //         </div>
                //     )
                // })
                return Object.keys(this.state.interviewsData[this.state.uid]).map((i) => {
                    return <div className="listBorder">
                        <Link to={`/interviews/${i}`} className="link" key={i}>interviewsandbox.com/interviews/{i}</Link>
                        <div className="list">{this.state.interviewsData[this.state.uid][i].createdon}</div>
                    </div>
                })
            }
        }
        return (
            <div className="interviewsTop" darkMode={this.props.darkMode}>
                <div>
                    <Header darkMode={this.props.darkMode}/>
                </div>
                <div className="interviews">
                    <h3>Interviews</h3>
                </div>
                <div className="addBtn" onClick={this.addInteview.bind(this)}>
                    <IconContext.Provider value={{ size: "1.5em",  style: { paddingRight: "0.2rem", marginTop: "0.1rem" } }}>
                        <GrAdd/>
                    </IconContext.Provider>
                </div>
                <div className="listTop">
                    {renderInterviews()}
                </div>
            </div>
        )
    }
}