import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FaPhoneAlt, FaComments } from "react-icons/fa";
import { RiMic2Line } from "react-icons/ri";
import { MdCamera } from "react-icons/md";
import "./VideoChat.css";
import Draggable from 'react-draggable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  // display: flex;
  // width: 100%;
`;

const Video = styled.video`
  // border: 2px solid blue;
  // box-shadow: 0 0 1px #282a36, 0 0 0 2.0px #282a36, 0 0 4.0px #282a36, 0 0 8.0px #fcee09;
  // width: 50%;
  // height: calc(17.5vh);
  position: relative;
  // left: 2.5%;
  // margin-left: 2%;
  // margin-top: 1%;
`;

const VideoChat = (props) => {
  const [yourID, setYourID] = useState("");
  const [members, setMembers] = useState([]);
  const [stream, setStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [toggleVid, setToggle] = useState(true);
  const [toggleAud, setAudToggle] = useState(true);
  const userVideo = useRef();
  const partnerVideo = useRef();
  const socket = useRef();

  useEffect(() => {
    const baseURL = process.env.NODE_ENV === "production" 
    ? "https://fathomless-journey-95730.herokuapp.com/" 
    : "http://localhost:8000/";

    socket.current = io(baseURL, {
        query: {
            sessionid: props.sessionid,
        },
    });

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setStream(stream);
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
      });

    socket.current.on("yourID", (id) => {
      setYourID(id);
    });
    socket.current.on("allUsers", (members) => {
      setMembers(members);
    });

    socket.current.on("hey", (data) => {
      setReceivingCall(true);
      setCaller(data.from);
      setCallerSignal(data.signal);
    });
    // callPeer()
  }, [props.sessionid]);

  // function toggleVideo(stream) {
  //   console.log("toggleVideo clicked");
  //   navigator.mediaDevices.getUserMedia({ video: false, audio: false });
  // }

  async function initializeStream() {
    if (stream) return;
  
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setStream(newStream);
  
      if (userVideo.current) {
        userVideo.current.srcObject = newStream;
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  }
  
  function toggleTrack(trackIndex, currentToggleValue, setToggleFunction) {
    if (!stream) return;
  
    const trackEnabled = stream.getTracks()[trackIndex].enabled;
    const newToggleValue = !trackEnabled;
  
    stream.getTracks()[trackIndex].enabled = newToggleValue;
    setToggleFunction(newToggleValue);
  }
  
  async function toggleVideo(constraints) {
    await initializeStream();
  
    if (stream) {
      toggleTrack(1, toggleVid, setToggle);
    }
  }
  
  async function toggleAudio(constraints) {
    await initializeStream();
  
    if (stream) {
      toggleTrack(0, toggleAud, setAudToggle);
    }
  }
  

  //takes in callUser & acceptCall
  function callPeer(id) {
    const peer = initializePeer(stream);

    setupPeerEvents(peer, id);

    listenForCallAcceptance(peer);
  }

  function initializePeer(stream) {
      return new Peer({
        initiator: true,
        trickle: false,
        stream: stream,
      });
  }

  function setupPeerEvents(peer, id) {
      peer.on("signal", (data) => handleSignal(data, id));
      peer.on("stream", handleStream);
  }

  function handleSignal(data, id) {
      socket.current.emit("callUser", {
          userToCall: id,
          signalData: data,
          from: yourID,
      });
  }

  function handleStream(stream) {
      if (partnerVideo.current) {
          partnerVideo.current.srcObject = stream;
      }
  }

  function listenForCallAcceptance(peer) {
      socket.current.on("callAccepted", (signal) => {
          setCallAccepted(true);
          peer.signal(signal);
      });
  }

  function acceptCall() {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });

    peer.on("signal", (data) => {
      socket.current.emit("acceptCall", { signal: data, to: caller });
    });

    peer.on("stream", (stream) => {
      partnerVideo.current.srcObject = stream;
    });

    peer.signal(callerSignal);

  }

  let UserVideo;
  if (stream) {
    UserVideo = <Draggable><Video className={props.whiteboardExtend === false ? "videoAdjust" : "videoDiv-ext"} playsInline muted ref={userVideo} autoPlay /></Draggable>;
  }

  let PartnerVideo;
  if (callAccepted) {
    PartnerVideo = <Draggable><Video className={props.whiteboardExtend === false ? "videoAdjust" : "videoDiv-ext"} playsInline ref={partnerVideo} autoPlay /></Draggable>;
  }

  const CallButton = () => {
    const buttonClass = callerSignal === undefined ? "callBtn" : "callBtn-pulse";
    
    return (
        <div onClick={acceptCall} className={buttonClass}>
            <CallIcon />
        </div>
    );
  }

  const CallIcon = () => {
      const iconContextValue = { size: "1.4em", style: { marginRight: "0.2rem", marginTop: "0.1rem" } };

      return (
          <IconContext.Provider value={iconContextValue}>
              <FaComments />
          </IconContext.Provider>
      );
  }

  let incomingCall = null;

  if (receivingCall && !callAccepted) {
      incomingCall = (
          <div>
              {/* If you ever want to display the caller's name uncomment below line */}
              {/* <p>{caller} is calling you</p> */}
              <CallButton />
          </div>
      );
  }

  return (
    <Container className="videoContainer">
      <Row className="buttonsRow">
        <div title="camera" className={toggleVid === true ? "toggleVid-on" : "toggleVid-off"} onClick={() => toggleVideo()}>
          <IconContext.Provider value={{ size: "1.4em", style: { marginRight: "0.2rem", marginTop: "0.1rem" } }}><MdCamera /></IconContext.Provider>
        </div>
        <div title="microphone" className={toggleAud === true ? "toggleAud-on" : "toggleAud-off"} onClick={() => toggleAudio()}> <IconContext.Provider value={{ size: "1.4em", style: { marginRight: "0.2rem", marginTop: "0.1rem" } }} ><RiMic2Line /></IconContext.Provider> </div>
        {callAccepted === false && members
          .filter((memberName) => memberName !== yourID)
          .map((memberName) => (
            <div
              key={memberName}
              className="toggleVid-on"
              onClick={() => callPeer(memberName)}
            >
              <IconContext.Provider
                value={{ size: "1.4em", style: { marginRight: "0.2rem", marginTop: "0.1rem" } }}
              >
                <FaPhoneAlt />
              </IconContext.Provider>

            </div>
          ))}
        <div>{incomingCall}</div>
      </Row>
      <div  className={props.whiteboardExtend === false ? "videoDiv" : "videoDiv-ext"}>
        {UserVideo}
        {PartnerVideo}
      </div>
    </Container>
  );
};

export default VideoChat;
