import React from "react";
import Header from "../../components/Header";
import "./Login.css";
import GoogleLogo from "../../components/google.png";
import GitbhubLogo from "../../components/github.png";
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword,
    GoogleAuthProvider, 
    GithubAuthProvider, 
    signInWithPopup 
} from "firebase/auth";
import { 
    getDatabase, 
    ref, 
    child, 
    update 
} from "firebase/database";

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: null,
            isLoading: false,
            newUser: true,
        };

        this.loginUser = this.loginUser.bind(this);
        this.createUser = this.createUser.bind(this);
        this.loginWithGoogle = this.loginWithGoogle.bind(this);
        this.loginWithGithub = this.loginWithGithub.bind(this);
    }

    onChange = (field) => (e) => {
        this.setState({ [field]: e.target.value });
    }

    handleThirdPartyLogin = (providerInstance) => {
        const auth = getAuth();
        signInWithPopup(auth, providerInstance).then((result) => {
            // Logic after successful sign-in...
            this.redirectAfterLogin();
        }).catch((error) => {
            console.error(`Error [${error.code}]: ${error.message}`);
        });
    }

    redirectAfterLogin = () => {
        const prevBoard = localStorage.getItem("prevBoard");
        if (prevBoard) {
            this.props.history.push(`/box/${JSON.parse(prevBoard)}`);
        } else {
            this.props.history.push("/");
        }
    }

    loginWithGithub = () => {
        this.handleThirdPartyLogin(new GithubAuthProvider());
    }

    loginWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            'login_hint': 'user@example.com'
        });
        this.handleThirdPartyLogin(provider);
    }

    handleLogin = (authFunction) => {
        const auth = getAuth();
        const { email, password } = this.state;

        authFunction(auth, email, password).then((userCredential) => {
            // Logic after successful login/signup...
            const user = userCredential.user;
            const dbRef = ref(getDatabase());
            update(child(dbRef, `users/${user.uid}`), {
                email: user.email,
                metadata: user.metadata
            });
            this.redirectAfterLogin();
        }).catch((error) => {
            console.error(`Error [${error.code}]: ${error.message}`);
        });
    }

    loginUser = () => {
        this.handleLogin(signInWithEmailAndPassword);
    }

    createUser = () => {
        this.handleLogin(createUserWithEmailAndPassword);
    }

    render() {
        const { darkMode } = this.props;
        const { email, password } = this.state;

        const getClassName = (base, darkVariant) => darkMode ? darkVariant : base;

        const InputField = ({ type, value, onChange, label, ...rest }) => (
            <div className="user-box">
                <input type={type} value={value} onChange={onChange} {...rest}></input>
                <label>{label}</label>
            </div>
        );

        const ThirdPartyLogin = ({ onClick, title, logoSrc, logoAlt }) => (
            <div className="GoogleLogin" onClick={onClick}>
                <button className={getClassName("loginWithGoogle", "loginWithGoogle-dark")}>Login with </button>
                <img title={title} className="GoogleLogo" src={logoSrc} alt={logoAlt} />
            </div>
        );

        return (
            <div className="loginTop" darkMode={darkMode}>
                <Header darkMode={darkMode} />
                <div className="login">
                <InputField type="text" value={email} onChange={this.onChange('email')} label="Email" />
                <InputField type="password" value={password} onChange={this.onChange('password')} label="Password" minLength="8" required />

                    <div className="loginBtn">
                        <button className={getClassName("letsRun-login", "letsRun-dark-login")} onClick={this.loginUser}>Login</button>
                        <button className={getClassName("letsRun-login", "letsRun-dark-login")} onClick={this.createUser}>Signup</button>
                    </div>

                    <ThirdPartyLogin onClick={this.loginWithGoogle} title="Google" logoSrc={GoogleLogo} logoAlt="google" />
                    <ThirdPartyLogin onClick={this.loginWithGithub} title="Github" logoSrc={GitbhubLogo} logoAlt="github" />
                </div>
            </div>
        );
    }
}
