import React from "react";
import Header from "../../components/Header";
import "./Login.css";
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getDatabase, ref, child, update } from "firebase/database";

// import config from "../../firebase-config.json"; // Config Json File

export default class Signup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            error: null,
            isLoading: false,
            // darkMode: false
        }
        this.onChangE = this.onChangeE.bind(this);
        this.onChangeP = this.onChangeP.bind(this);
        this.createUser = this.createUser.bind(this);
        // app.initializeApp(config);

        // this.auth = app.auth();
    }

    componentDidMount = () => {

    }

    onChangeE = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onChangeP = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    
    handleAction = () => {
        this.setState({
            isLoading: true
        })
        // signInWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then(() => {
            this.setState({
                isLoading: false
            })
            this.props.history.push("/");
        }).catch(error => {
            this.setState({
                error: error.message,
                isLoading: false
            })
        }
        )
    }

    createUser = () => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            localStorage.setItem("user", JSON.stringify(user));
            const dbRef = ref(getDatabase());
            update(child(dbRef, `users/${user.uid}`), {
                email: user.email,
                metadata: user.metadata
            })
            // ...
            setPersistence(auth, browserLocalPersistence)
            .then(() => {
                // In memory persistence will be applied to the signed in Google user
                // even though the persistence was set to 'none' and a page redirect
                // occurred.
                // return signInWithRedirect(auth, provider);
                if(localStorage.getItem("prevBoard")) {
                    let prevBoard = JSON.parse(localStorage.getItem("prevBoard"));
                    this.props.history.push(`/box/${prevBoard}`);
                } else {
                    this.props.history.push("/");
                }
            })
        })
        .catch((error) => {
            // eslint-disable-next-line 
            const errorCode = error.code;
            // eslint-disable-next-line 
            const errorMessage = error.message;
            // ..
        });
    }


    render(){
        return (
            <div className="loginTop" darkMode={this.props.darkMode}>
            <Header
              darkMode={this.props.darkMode}

            />
            <div className="login">
                <div className="user-box">
                    <input type="text" value={this.state.email} onChange={this.onChangeE} name=""></input>
                    <label>Email</label>
                </div>
                <br/>
                <div className="user-box">
                    <input type="password" value={this.state.password} onChange={this.onChangeP} minLength="8" required></input>
                    <label value={this.state.password} >Password</label>
                </div>
                <br/>
                <button className={this.props.darkMode === true ? "letsRun-dark" : "letsRun"} onClick={this.createUser}>Signup</button>
            </div>
            </div>
        )
    }
} 