import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "./DropdownTheme.css";

const DropdownTheme = ({ title, theme, toggleItem }) => {
  const [listOpen, setListOpen] = useState(false);

  const toggleList = () => {
    setListOpen(prevState => !prevState);
  };

  return (
    <div
      className="dd-wrapperTheme"
      onMouseEnter={toggleList}
      onMouseLeave={toggleList}
    >
      <div className="dd-header">
        <div className="dd-header-title">
          {title}
          {listOpen ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {listOpen && (
        <ul className="dd-list-item">
          {theme.map(item => (
            <li key={item.id} onClick={() => toggleItem(item.id, item.key)}>
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownTheme;