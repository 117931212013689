import React from "react";
import Header from "../../components/Header";
import rand from "random-key";
// eslint-disable-next-line
import database from "firebase/compat";
import Typewriter from "typewriter-effect";
import ReactGA from "react-ga";
import "./Home.css";
// import Judge0 from "../../components/Judge0.png";
import { onValue, child, getDatabase, ref, set } from "firebase/database";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { IconContext } from "react-icons";
import { CiLogin, CiLogout } from "react-icons/ci";

function initializeAnalytics() {
  ReactGA.initialize("UA-154969560-1");
  ReactGA.pageview("/Home");
}

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: rand.generate(5),
      num: null,
      login: false,
    };
    this.loginRender = this.loginRender.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentDidMount = () => {
    // database()
    //   .ref("code-sessions")
    //   .on("value", (s) => {
    //     this.setState({ num: s.numChildren() });
    //   });
    const dbRef = ref(getDatabase());
    onValue(child(dbRef, `code-sessions/`), (s) => {
      this.setState({ num: Object.keys(s.val()).length });
    })
    
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.setState({login: true});
        // ...
      } else {
        // User is signed out
        // ...
        this.setState({login: false});
      }
    });
  };

  // This function creates a new entry in our database when the user hits the 'Let's go' button
  letsGo = () => {
    // database()
    //   .ref("code-sessions/" + this.state.key)
    //   .set({
    //     content:
    //       '# Welcome to your Interview Sandbox! \n\n# 1 - Just share this link with another person \n# \t  to get them on the same page.\n# 2 - Once they join, you\'ll see the call button.\n# 3 - The code, code output, and drawings are synced\n#     in real time between users.\n# 4 - Select between Javascript, Python, Ruby, Swift,\n#     Java, Go, Kotlin, PHP, R, and Scala.\n\n# Try running the code below to see the ouput!\n\nprint("hello world")',
    //     createdon: new Date().toLocaleString(),
    //   });
    const dbRef = ref(getDatabase());
    set(child(dbRef,`code-sessions/${this.state.key}`),{
      content:
          '# Welcome to your Interview Sandbox! \n\n# 1 - Just share this link with another person \n# \t  to get them on the same page.\n# 2 - Once they join, you\'ll see the call button.\n# 3 - The code, code output, and drawings are synced\n#     in real time between users.\n# 4 - Select between Javascript, Python, Ruby, Swift,\n#     Java, Go, Kotlin, PHP, R, and Scala.\n\n# Try running the code below to see the ouput!\n\nprint("hello world")',
      createdon: new Date().toLocaleString(),
    })
    this.props.history.push("/box/" + this.state.key);
  };

  logout(){
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("logout");
    }).catch((error) => {
      // An error happened.
    });
  };
  
  login(){
    this.props.history.push("/login");
  };

  loginRender() {
    if(this.state.login === true){
      return <div title="logout" onClick={this.logout.bind(this)}>
        <IconContext.Provider value={{ size: "1.35em", style: { paddingRight: "0.2rem", marginTop: "0.1rem", position: "fixed", top: "11px", right: "1%" }}}>
          <CiLogout/>
        </IconContext.Provider>
      </div>
    } else {
      return <div title="login" onClick={this.login.bind(this)}>
        <IconContext.Provider value={{ size: "1.35em", style: { paddingRight: "0.2rem", marginTop: "0.1rem", position: "fixed", top: "11px", right: "1%" }}} onClick={this.logout.bind(this)}>
          <CiLogin/>
        </IconContext.Provider>
      </div> 
    }
  };

  render() {
    initializeAnalytics();
    const { darkMode } = this.props;
    const highlightClass = darkMode ? "highlight-dark" : "highlight";
    const buttonDivClass = darkMode ? "buttonDiv-dark" : "buttonDiv";
    const btnClass = darkMode ? "btn letsGo-dark" : "btn letsGo";

    return (
        <>
            <Header
                extras={this.state.num ? `Total ${this.state.num}+ Shares` : null}
                darkMode={darkMode}
            />
            {this.loginRender()}
            <div className="homepage">
                <p className="title">
                    Share Code with
                    <Typewriter
                        className="homepage"
                        options={{
                            strings: [
                                "interviewers",
                                "interviewees",
                                "coworkers",
                                "classmates",
                                "friends",
                                "hackers"
                            ],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                    in <span className={highlightClass}>Realtime</span>.
                    <br />
                    Anywhere, Anytime and with <span className={highlightClass}>Anyone</span>.
                </p>

                <p className="sub-title">
                    A real time code sharing app where you can write code, execute it, video chat, and use a whiteboard with apple pencil.
                    <br />
                    <br />
                    Oh, and registered users can tap into AI that helps you write code.
                </p>

                <div className={buttonDivClass}>
                    <button className={btnClass} onClick={this.letsGo}>
                        Let's go
                    </button>
                </div>
            </div>
        </>
    );

  }
}
